import { useMemo } from 'react';
import { BrandedHeading as ChameleonBrandedHeading } from '@mediahuis/chameleon-react';

import { createTrackingClickHandler } from '@hubcms/data-access-tracking';
import { createClickNavigationEvent, mapClickData } from '@hubcms/utils-tracking';

import type { BrandedHeadingProps } from './types';

export default function BrandedHeading({
  title,
  href,
  linkText = '',
  headerLevel = 2,
  size = 'sm',
  trackingData,
}: BrandedHeadingProps) {
  const handleOnClick = useMemo(() => {
    const clickNavigationData = mapClickData(trackingData);

    if (!clickNavigationData) {
      return undefined;
    }

    return createTrackingClickHandler(createClickNavigationEvent(clickNavigationData));
  }, [trackingData]);

  if (!title) {
    return null;
  }

  return (
    <ChameleonBrandedHeading
      onClick={handleOnClick}
      as={`h${headerLevel}`}
      size={size}
      actionUrl={href ? href : null}
      actionLabel={linkText ? linkText : null}
    >
      {title}
    </ChameleonBrandedHeading>
  );
}
